<template>
    <div style="text-align:center">
        <img class="wxwork-qrcord-uesr" src="@/assets/WechatIMG12439.jpeg">
    </div>
</template>

<script>
    export default {
        name:'applySuccess'
    }
</script>

<style lang="less" scoped>
    .wxwork-qrcord-uesr{
        width: 90%;
        margin: 0 auto;
    }
</style>